<template>
  <section class="sponsors">
    <div class="w-100 container">
      <div class="d-flex-column h-100">
        <h2 class="title-grand-primary text-center">Patrocinadores</h2>
        <section class="box-sponsor">
          <agile
            :options="myOptions"
            ref="carousel"
            class="logos-box container"
            v-if="sponsors && Object.values(sponsors).length != 0"
          >
            <div v-for="(sponsor, key) in sponsors" :key="key" class="box-logo">
              <a
                target="_blank"
                :href="sponsor.web"
                :title="
                  'Logotipo de ' + sponsor.name + '. Abre en ventana nueva'
                "
              >
                <img
                  :src="sponsor.logo.url"
                  :alt="
                    'Logotipo de ' + sponsor.name + '. Abre en ventana nueva'
                  "
                />
              </a>
            </div>
          </agile>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import { contentStore } from "@/stores/contents";
import { VueAgile } from "vue-agile";
export default {
  name: "SponsorsLayout",
  components: {
    agile: VueAgile,
  },
  setup() {
    const store = contentStore();
    // store.loadSubventions()
    return {
      store,
    };
  },
  mounted() {
    this.store.loadOrganizations({ catId: 8 });
  },
  data: () => ({
    myOptions: {
      responsive: [
        {
          breakpoint: 900,
          settings: {
            navButtons: false,
            slidesToShow: 4,
            dots: false,
            centerMode: true,
            unagile: true,
          },
        },
        {
          breakpoint: 800,
          settings: {
            navButtons: true,
            slidesToShow: 3,
            dots: false,
            unagile: false,
            autoplay: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            navButtons: true,
            slidesToShow: 2,
            dots: false,
            unagile: false,
            autoplay: false,
            centerMode: false,
          },
        },
        {
          breakpoint: 0,
          settings: {
            navButtons: true,
            slidesToShow: 1,
            dots: false,
            autoplay: false,
            centerMode: false,
          },
        },
      ],
    },


  }),
  computed: {
    sponsors() {
      if (this.store.organizations[8]) {
        return this.store.organizations[8].items;
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/mixins";

.sponsors {
  background-image: url(/img/layout/sponsors.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  height: auto;
}

.box-sponsor {
  background: white;
  border-radius: 28px;
  padding: 30px;
  margin-bottom: 230px;
}

.box-logo {
  width: 100%;
  max-width: 25%;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 10px;

    img {
      width: auto;
      height: auto;
      object-fit: contain;
      max-width: 100%;
    }
  }
}

/* .agile__list {
    width: 100vw !important;
  } */

.agile__slide {
}
@media screen and (max-width: 900px) {
  .box-logo {
    max-width: 100%;
  }
}
</style>
