import { createApp } from 'vue'
import { createPinia } from "pinia"
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import utils from "@/utils/utils.js";
import { i18n } from './i18n'
import './vee-validate'
import App from './App.vue'
import { VueReCaptcha } from "vue-recaptcha-v3";
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';

//Configure Axios
axios.defaults.baseURL = process.env.VUE_APP_BACK;
axios.defaults.withCredentials = true

createApp(App)
    .use(router)
    .use(Markdown)
    .use(VueAxios, axios)
    .use(i18n)
    .use(createPinia())
    .use(VueReCaptcha, { siteKey: "6LfcyNMiAAAAAINRhqesZs-eoax8Q67-rvu5Rahp" })
    .use(OpenLayersMap)
    .use(LoadingPlugin)
    .use(VCalendar, {})
    .mixin({
        methods: {
            scrollSection: (params) => {
                if (params) {
                    const offsetTop = document.getElementById(params).offsetTop - 150;
                    setTimeout(() => {
                        scroll({
                            behavior: "smooth",
                            top: offsetTop,
                        });
                    }, 200);
                }
            },
        },
    })
    .mount('#app')
