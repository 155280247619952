<template lang="html">

  <section class="alert-banner" v-if="store.specialAvises && Object.values(store.specialAvises).length != 0">
    <agile :options="myOptions" ref="carousel">
      <div v-for="(avise, index) in Object.values(store.specialAvises)" :key="index">
        <div class="container slider-cont">
          <img src="/icons/alert-black.png" alt="Icono alerta">
          <strong>{{avise.message}}</strong>
          <a :href="avise.url" v-if="avise.url && avise.cta" class="btn-underline">
            <span v-if="avise.cta">{{avise.cta}}</span>
            <template v-else>
              <span>Más información</span>
            </template>
          </a>
        </div>
      </div>


    </agile>

  </section>

</template>

<script lang="js">
  import {
    VueAgile
  } from "vue-agile";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'alert-banner',
    components: {
      agile: VueAgile
    },
    props: [],

    setup() {
      const store = contentStore();
      store.loadSpecialAvises()
      return {
        store
      }
    },
    mounted() {

    },
    data() {
      return {
        myOptions: {
          responsive: [{
            breakpoint: 0,
            settings: {
              autoplay: true,
              fade: true,
              navButtons: false,
              slidesToShow: 1,
              dots: false,
              speed: 2000
            },
          }],
        },
      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  @import "@/styles/general.scss";

  .alert-banner {
    background: $blueLight;
    padding: 10px 0;
    height: 100%;
    /* max-height: 55px; */

    .slider-cont {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;

      img {
        width: 100%;
        max-width: 30px;
      }

      strong {
        font-family: 'Roboto', serif;
        font-size: 16px;
        color: black;
        width: max-content;
        max-width: calc(100% - 50px);
        padding-left: 10px;
      }

      .btn-underline {
        font-family: 'Roboto', serif;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
        margin-left: 15px;
        text-decoration: none;
        border-bottom: 1px solid white;
      }
    }
  }
</style>