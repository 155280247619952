<template>
  <section>
    <div class="vl-parent">
      <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
    </div>
    <header-layout />
    <div>
      <alert-banner />
      <section class="help-section background-orange">
        <!-- <button type="button" class="btn p-left-30-px p-10-px text-center text-white cursor-pointer w-100">
          <img class="img-help" src="/icons/carrousel-help.svg" alt="">
          <br>
          <span class="text-bold">¿Te ayudamos?</span>
        </button> -->
      </section>
<!--       <button id="INDmenu-btn" title="Herramienta de accesibilidad">
        <img alt="Herramienta de accesibilidad" title="Herramienta de accesibilidad" id="imgAcc" src="/img/accesibilidad-universal.png">
      </button> -->
      <transition name='custom-classes' enter-active-class="animate__animated animate__fadeIn animate__slow">
        <router-view />
      </transition>
      <sponsors-layout />
      <footer-layout />

    </div>
  </section>
</template>

<script>
  import utils from "@/utils/utils.js";
  import HeaderLayout from "@/components/Layout/HeaderLayout";
  import AlertBanner from "@/components/Layout/AlertBanner.vue";
  import SponsorsLayout from "@/components/Layout/SponsorsLayout";
  import FooterLayout from "@/components/Layout/FooterLayout";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/css/index.css';
  
  export default {
    components: {
      FooterLayout,
      SponsorsLayout,
      HeaderLayout,
      AlertBanner,
      Loading
    },
    data() {
      return {
        isLoading: false,
        fullPage: true
      }
    },
    beforeMount() {
      this.doAjax()
    },
    mounted() {
      if (document.querySelector('.cky-btn-revisit-wrapper')) {
        document.querySelector('.cky-btn-revisit-wrapper').setAttribute('data-tooltip', 'Configuración')
      }
    },
    methods: {
      doAjax() {
        this.isLoading = true;
        // simulate AJAX
        setTimeout(() => {
          this.isLoading = false
        }, 1000)
      },
      onCancel() {
        console.log('User cancelled the loader.')
      }
    }
  }

</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;700;900&display=swap');
  @import url(https://kit.fontawesome.com/3500c0c80e.css);
  @import "@/styles/general.scss";
  @import "@/styles/mixins.scss";

  .btn {
    @include sm {
      padding: 6px !important;
    }

    &>span {
      @include sm {
        font-size: 14px;
      }
    }
  }

  .img-help {
    @include sm {
      width: 1.2rem;
    }
  }
</style>