export default {
    generateTextFilters(text, columns, textFilter = 1) {
        var filters = [];
        var column = null;
        if (text == null) {
            return filters;
        }
        for (column of columns) {
            filters.push({
                filterPack: true,
                filters: [{
                    name: column,
                    search: text,
                    textFilter: textFilter,
                }],
            })
        }
        return filters;
    },
   
    LoaderScrollToTop(position) {
        setTimeout(function () {
            let element = document.getElementById("app");
            element.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }, 200);
        return position
    },
    CutText(texto, limite) {
        var puntosSuspensivos = "...";
        if (texto.length > limite) {
            texto = texto.substring(0, limite) + puntosSuspensivos;
        }

        return texto;
    },
    loadLocaleMessages() {
        let locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        let messages = {};
        locales.keys().forEach(key => {
            let matched = key.match(/([A-Za-z0-9-_]+)\./i)
            if (matched && matched.length > 1) {
                let locale = matched[1]
                messages[locale] = locales(key)
            }
        });
        return messages;

    }
}