import {
  createRouter,
  createWebHistory
} from 'vue-router'
const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title:
        "CERMI| Comité Español de Representantes de Personas con Discapacidad",
      description:
        "CERMI, plataforma de representación, defensa y acción de la ciudadanía española con discapacidad. Más de 800 organizaciones en comprometidas por sus derechos.",
    },
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/colecciones/:id",
    name: "colecciones",
    props: true,
    meta: {
      title: "Colecciones CERMI ",
      description:
        "Abyectos, telefónica accesible, empero, Cermi.es, anales de derecho y discapacidad, ONU, generosidad, inclusión y diversidad.",
    },
    component: () => import("@/views/Collections.vue"),
  },
  {
    path: "/colecciones/:cat/:doc",
    name: "DocCollection",
    props: true,
    meta: {
      title: "Colecciones CERMI ",
      description:
        "Abyectos, telefónica accesible, empero, Cermi.es, anales de derecho y discapacidad, ONU, generosidad, inclusión y diversidad.",
    },
    component: () => import("@/views/PageDocCollection.vue"),
  },
  {
    path: "/transparencia",
    name: "transparencia",
    meta: {
      title:
        "Transparencia CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "La transparencia hacia todos sus grupos de interés y el buen gobierno de la organización son compromisos que el CERMI ha adquirido con convicción y que pretende hacer efectivos en todos sus órdenes de actividad.",
    },
    component: () => import("@/views/Clarity.vue"),
  },
  {
    path: "/ecohuella-cermi",
    name: "ecohuella",
    meta: {
      title:
        "EcoHuella CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "La transparencia hacia todos sus grupos de interés y el buen gobierno de la organización son compromisos que el CERMI ha adquirido con convicción y que pretende hacer efectivos en todos sus órdenes de actividad.",
    },
    component: () => import("@/views/Ecohuella.vue"),
  },
  {
    path: "/actualidad",
    name: "actualidad",
    props: true,
    meta: {
      title: "Actualidad CERMI | Noticias de la Plataforma",
      description:
        "Noticias, agenda, agenda semanal de la presidencia, galería, exposiciones, contacto de la prensa.",
    },
    component: () => import("@/views/News.vue"),
  },
  {
    path: "/agenda-del-presidente",
    name: "president-agend",
    props: true,
    component: () => import("@/views/PresidentAgend.vue"),
  },
  {
    path: "/actualidad/agenda",
    name: "events",
    props: true,
    component: () => import("@/views/Events.vue"),
  },
  {
    path: "/actualidad/agenda/historico",
    name: "HemerotecaEvents",
    props: true,
    component: () => import("@/views/HemerotecaEvents.vue"),
  },
  {
    path: "/organos-de-gobierno/:idDeparment/:id",
    name: "organos-de-gobierno",
    props: true,
    component: () => import("@/views/GovernmentBodies.vue"),
  },
  {
    path: "/imago-cermi/:Nyear",
    name: "multimediaImago",
    props: true,
    component: () => import("@/views/HemerotecaImago.vue"),
  },
  {
    path: "/galeria/:Nyear",
    name: "multimediaImagenes",
    props: true,
    component: () => import("@/views/HemerotecaGaleria.vue"),
  },
  {
    path: "/organos-de-gobierno",
    name: "organos de gobierno",
    props: true,
    component: () => import("@/views/GovernmentBodies.vue"),
  },
  {
    path: "/premios/:slug",
    name: "premios-cermi",
    props: true,
    component: () => import("@/views/Rewards.vue"),
  },
  {
    path: "/:typeContent/:slug",
    name: "page-entry",
    props: true,
    component: () => import("@/views/Page-entry.vue"),
  },
  {
    path: "/contacto",
    name: "contacto",
    meta: {
      title: "Contacto CERMI | Dónde Estamos y Cómo Llegar ",
      description:
        "Contacta con nosotros vía telefónica o vía formulario. Te esperamos. 91 360 16 78",
    },
    component: () => import("@/views/Contact.vue"),
  },
  {
    path: "/pre-registro",
    name: "politica-cookies",
    component: () => import("@/views/CookiesPolicyView"),
    meta: {
      title:
        "Información sobre pre-registros informáticos de uso de esta página CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "Cermi utiliza los preregistros con el fin de facilitar y ofrecer a cada usuario información que es de su interés o que puede ser de su interés, en atención al uso que realiza de los Servicios.",
    },
  },
  {
    path: "/politica-privacidad",
    name: "politica-privacidad",
    component: () => import("@/views/PrivacyPolicyView"),
    meta: {
      title:
        "Política de privacidad CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "Siguiendo los principios de licitud, lealtad y transparencia, y en cumplimiento de la normativa en materia de protección de datos personales, ponemos a su disposición la presente Política de Privacidad.",
    },
  },
  {
    path: "/canal-audiovisual",
    name: "canal-audiovisual",
    component: () => import("@/views/AudiovisualChannelView"),
  },
  {
    path: "/que-es-cermi/:slug?",
    name: "que-es-cermi",
    meta: {
      title:
        "Qué es CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "Una actividad constante en favor de las personas con discapacidad, cuyo objetivo es el de mejorar sus condiciones de vida y las de sus familias.",
    },
    component: () => import("@/views/WhatIsCermi"),
  },
  {
    path: "/organizaciones/:slug",
    name: "organizaciones",
    meta: {
      title:
        "Organizaciones CERMI | Socios, Entidades, Organizaciones, Patrocinadores y Colaboradores",
      description:
        "CERMI, un conjunto de socios fundadores, fundadores, socios adheridos y socios ordinarios que unidos a entidades, organizaciones y otros organismos trabajan a favor de las personas con discapacidad.",
    },
    props: true,
    component: () => import("@/views/Organizations"),
  },
  {
    path: "/exposiciones",
    name: "exposiciones",
    props: true,
    component: () => import("@/views/Expositions"),
  },
  {
    path: "/exposiciones/:slug",
    name: "exposiciones-vista",
    props: true,
    component: () => import("@/views/ViewExpositions"),
  },
  {
    path: "/condecoraciones-y-premios",
    name: "condecoraciones-y-premios",
    meta: {
      title: "Condecoraciones y Premios CERMI ",
      description:
        "Desde 1999, son ya 26 el número de premios que ha recogido el Comité Español de Representantes de Personas con Discapacidad, CERMI",
    },
    props: true,
    component: () => import("@/views/DecorationsAwards"),
  },
  {
    path: "/memorias",
    name: "memorias",
    meta: {
      title:
        "Memorias CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "Memoria de Actividades del CERMI Estatal. Memoria RSE-D CERMI. Documentación. ",
    },
    props: true,
    component: () => import("@/views/Memories"),
  },
  {
    path: "/informacion-economica",
    props: true,
    name: "Información-economica",
    meta: {
      title:
        "Información Económica CERMI | Subvenciones y Ayudas, Colaboraciones Económicas, Cuentas y Presupuestos Anuales",
      description:
        "Haciendo efectivo el principio de transparencia de CERMI, se pone a disposición del usuario las subvenciones y ayudas de CERMI, las colaboraciones económicas y los presupuestos anuales",
    },
    component: () => import("@/views/EconomicInformation"),
  },
  {
    path: "/convenios",
    props: true,
    name: "convenios",
    component: () => import("@/views/Convenios"),
  },
  {
    path: "/como-asociarce-al-cermi",
    name: "como-asociarse-al-cermi",
    component: () => import("@/views/HowToJoinCermi"),
  },
  {
    path: "/defensa-legal",
    name: "defensa-legal",
    meta: {
      title: "Defensa Legal CERMI | Orientación Jurídica",
      description:
        "En CERMI prestamos orientación jurídica a las personas con discapacidad y/o sus familias, y a sus entidades, que lo soliciten.",
    },
    component: () => import("@/views/LegalDefense"),
  },
  {
    path: "/asi-nos-organizamos/:slug?",
    name: "asi-nos-organizamos",
    meta: {
      title:
        "CERMI, Así nos Organizamos | Organigrama, Órganos de Gobierno y Equipo Humano",
      description:
        "Descubre quién forma parte del organigrama CERMI Estatal, los órganos de gobierno y el equipo humano que constituye CERMI y no perciben ninguna remuneración por el desempeño de sus responsabilidades sociales",
    },
    component: () => import("@/views/HowOrganize"),
  },
  {
    path: "/iniciativas-cermi",
    props: true,
    name: "iniciativas-cermi",
    meta: {
      title:
        "Iniciativas CERMI | Ámbitos de Actuación, Acciones Internacionales, Proyectos e Iniciativas",
      description:
        "Necesidades y demandas preferentes de las personas con discapacidad que constituyen el ámbito de actuación de CERMI, ODSUE, acciones internacionales, Europa más social.",
    },
    component: () => import("@/views/InitiativeCermi"),
  },
  {
    path: "/iniciativa-cermi/:slug?",
    name: "proyectos-ficha-cermi",
    props: true,
    component: () => import("@/views/CermiToken"),
  },
  {
    path: "/cermis-autonomicos/:slug?/:id?",
    name: "cermis-autonomicos",
    props: true,
    component: () => import("@/views/AutonomicCermi"),
  },
  {
    path: "/planes-actuacion-anuales/",
    name: "planes-actuacion-anuales",
    props: true,
    component: () => import("@/views/AnnualActionPlans"),
  },
  {
    path: "/accesibilidad",
    name: "accesibilidad",
    component: () => import("@/views/Accessibility"),
    meta: {
      title:
        "Accesibilidad CERMI| Comité Español de Representantes de Personas con Discapacidad",
      description:
        "El acceso al sitio web de CERMI, así como el uso que pueda hacerse de la información que contiene, son de la exclusiva responsabilidad de la persona usuaria. CERMI no se responsabilizará de ninguna consecuencia, daño o perjuicio que pudieran derivarse del uso de este sitio web",
    },
  },
  {
    path: "/aviso-legal",
    name: "aviso-legal",
    component: () => import("@/views/LegalWarning"),
    meta: {
      title:
        "Aviso legal CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description: "Aviso Legal CERMI",
    },
  },
  {
    path: "/normas-reguladoras/:slug?/:subSlug?",
    name: "normas-reguladoras",
    meta: {
      title:
        "Normas Reguladoras CERMI | Código del Gobierno, Código Ético, Plan de Igualdad y Calidad",
      description:
        "CERMI, plataforma de representación, defensa y acción de la ciudadanía española con discapacidad. Más de 800 organizaciones en comprometidas por sus derechos.",
    },
    props: true,
    component: () => import("@/views/StandardCodeConventions"),
  },
  {
    path: "/voluntariado-social-cermi",
    name: "voluntariado-social-cermi",
    component: () => import("@/views/Volunteering"),
  },
  {
    path: "/busqueda",
    name: "results",
    component: () => import("@/views/Results"),
  },
  {
    path: "/bizum",
    name: "bizum",
    meta: {
      title: "CERMI Bizzum | Donativos vía Bizzum a CERMI Estatal",
      description:
        "Introduce el código del Comité Español de Representantes de Personas con Discapacidad (CERMI): 03851 y realice la donación que desee.",
    },
    component: () => import("@/views/Bizum"),
  },
  {
    path: "/colaboracion-asociativa",
    name: "colaboracion-asociativa",
    component: () => import("@/views/CollaborativeAssociation"),
  },
  {
    path: "/servicio-asistencia-pmr",
    name: "servicio-asistencia-pmr",
    component: () => import("@/views/PMR.vue"),
  },
  {
    path: "/sufre-discriminacion",
    name: "sufre-discriminacion",
    component: () => import("@/views/SufferDiscrimination"),
  },
  {
    path: "/hemeroteca",
    name: "hemeroteca",
    component: () => import("@/views/Hemeroteca"),
  },
  {
    path: "/novedades",
    name: "novedades",
    component: () => import("@/views/NoveltiesHemeroteca"),
  },
  /* Fixed Pages */
  {
    path: "/transparencia",
    name: "transparencia",
    meta: {
      title: "Transparencia CERMI | Comité Español de Representantes de Personas con Discapacidad",
      description:
        "La transparencia hacia todos sus grupos de interés y el buen gobierno de la organización son compromisos que el CERMI ha adquirido con convicción y que pretende hacer efectivos en todos sus órdenes de actividad.",
    },
    component: () => import("@/views/Clarity.vue"),
  },
  {
    path: "/i-congreso-estatal-de-juventud-con-discapacidad",
    name: "i-congreso-estatal-de-juventud-con-discapacidad",
    component: () => import("@/views/FixedPage.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: (to) => {
      return {
        path: "/",
        query: {
          httpstatus: 301,
        },
      };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.name == 'organizaciones' || to.params.idDay) {
      return
    }
    else {
      return { top: 0 }
    }
  },
  routes
})
router.beforeEach((to, from, next) => {
  let meta = to.meta
  if (!!meta.title && !!meta.description) {
    document.title = meta.title
    document.head.querySelector("[name=description]").content = meta.description;
    document.head.querySelector("[property='og:title']").content = meta.title;
    document.head.querySelector("[property='og:description']").content = meta.description;
    document.head.querySelector("[property='og:url']").content = location.href;
  }
  if (to.query.httpstatus) {
    let metaComponent = document.createElement('meta');
    metaComponent.name = "prerender-status-code";
    metaComponent.content = to.query.httpstatus;
    document.head.appendChild(metaComponent);
    if (to.query.httpstatus == 301) {
      metaComponent = document.createElement('meta');
      metaComponent.name = "prerender-header";
      metaComponent.content = "Location: " + location.href;
      document.head.appendChild(metaComponent);
    }
    delete to.query.httpstatus;
  }
  next();
})
export default router
