import { defineStore } from 'pinia'
import axios from 'axios'
import moment from 'moment'
import utils from '@/utils/utils.js'
const ENTRIES_PAGE = 21;
function getEnty(object, slug) {
    var entry = null
    Object.keys(object).forEach(key => {
        console.log(key)
        if (object[key][slug]) {
            entry = object[key][slug];
        }
    });
    return entry;
}

function checkForNewPage(object, page) {
    if (object.items[page] != null && Object.keys(object.items[page]).length > 0) {
        return null;
    } else {
        return page;
    }

}

export const contentStore = defineStore("content", {
  state: () => ({
    loading: false,
    banners: {},
    organizations: {},
    subventions: {},
    agreements: {},
    budgetsAnnual: {},
    memoriesAnnual: {},
    memoriesRSE: {},
    otherMemories: {},
    awards: {},
    news: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
      lastFilters: {
        date: new Date().getFullYear(),
      },
    },
    routes: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
      lastFilters: {
        date: new Date().getFullYear(),
      },
    },
    novelties: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
      lastFilters: {
        date: new Date().getFullYear(),
      },
    },
    videos: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
      lastFilters: {
        date: new Date().getFullYear(),
      },
    },
    images: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
      lastFilters: {
        date: new Date().getFullYear(),
      },
    },
    documents: {},
    events: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
    },
    eventsLatest: {
      viewPage: 1,
      items: {},
      minPage: 1,
      maxPage: 10,
      lastFilters: {
        date: new Date().getFullYear(),
      },
    },
    eventsCalendar: {
      items: {},
      lastFilters: {
        starDate: null,
        endDate: null,
      },
    },
    specialAvises: {},
    expositions: {},
    rewards: {},
    documentAutodefense: {},
    routesTop: {},
    routesSlider: {},
    routesOther: {},
    collections: {},
    collectionsPagination: {
      actual: 1,
      init: 0,
      end: 9,
    },
    projects: {},
    plans: {},
    rules: {},
    easyRead: {
      active: false,
      text: "en lectura fácil",
    },
    presidentAgenda: {},
    goverments: {},
    employees: {},
    pagesFixed: {},
  }),
  getters: {
    setinitPageCollections: function (state) {
      return (item) => {
        console.log(item);
        return (state.collectionsPagination.init = item);
      };
    },
    setEndPageCollections: function (state) {
      return (item) => {
        return (state.collectionsPagination.end = item);
      };
    },
    setPageActualCollections: function (state) {
      return (item) => {
        console.log(item);
        return (state.collectionsPagination.actual = item);
      };
    },
    setPageEntries: function (state) {
      return (page) => {
        return (state.news.viewPage = page);
      };
    },
    setPagesNovelties: function (state) {
      return (page) => {
        return (state.novelties.viewPage = page);
      };
    },
    setYearNovelties: function (state) {
      return (year) => {
        return (state.novelties.lastFilters.date = year);
      };
    },

    setPagesVideos: function (state) {
      return (page) => {
        return (state.videos.viewPage = page);
      };
    },
    setPagesImages: function (state) {
      return (page) => {
        return (state.images.viewPage = page);
      };
    },
    setPagesEvents: function (state) {
      return (page) => {
        return (state.eventsLatest.viewPage = page);
      };
    },

    getEntries: (state) => (page) => {
      if (state.news.items[page]) {
        return state.news.items[page];
      }
    },
    getRoutes: (state) => (page) => {
      if (state.routes.items[page]) {
        return state.routes.items[page];
      }
    },
    getVideos: (state) => (year, page) => {
      if (state.videos.items[year]) {
        return state.videos.items[year][page];
      }
    },
    getImages: (state) => (year, page) => {
      if (state.images.items[year]) {
        return state.images.items[year][page];
      }
    },
    getEvents: (state) => (year, page) => {
      if (state.events.items[year]) {
        return state.events.items[year][page];
      }
    },

    getEventsLatest: (state) => (year, page) => {
      if (state.eventsLatest.items[year]) {
        return state.eventsLatest.items[year][page];
      }
    },

    getNovelties: (state) => (page) => {
      if (state.novelties.items[page]) {
        return state.novelties.items[page];
      }
    },
    getNoveltiesPage: (state) => {
      return state.novelties.viewPage;
    },
    getNoveltiesDate: (state) => {
      return state.novelties.lastFilters.date;
    },

    getPagesNovelties: (state) => (page) => {
      if (state.novelties.items[page]) {
        var pages = Math.ceil(state.novelties.items.maxItems / ENTRIES_PAGE);
        return pages;
      }
    },
    getEntriesPage: (state) => {
      return state.news.viewPage;
    },

    getPagesEntries: (state) => (page) => {
      if (state.news.items[page]) {
        var pages = Math.ceil(state.news.items.maxItems / ENTRIES_PAGE);
        return pages;
      }
    },

    getRoutesPage: (state) => {
      return state.routes.viewPage;
    },

    getPagesRoutes: (state) => (page) => {
      if (state.routes.items[page]) {
        var pages = Math.ceil(state.routes.items.maxItems / ENTRIES_PAGE);
        return pages;
      }
    },
    getEmployeeById: (state) => (id) => {
      return state.employees[id];
    },
    getPagesVideos: (state) => (year) => {
      if (state.videos.items[year]) {
        var pages = Math.ceil(state.videos.items[year].maxItems / ENTRIES_PAGE);
        return pages;
      }
    },
    getPagesImages: (state) => (year) => {
      if (state.images.items[year]) {
        var pages = Math.ceil(state.images.items[year].maxItems / ENTRIES_PAGE);
        return pages;
      }
    },

    getPagesEventsLatests: (state) => (year) => {
      if (state.eventsLatest.items[year]) {
        var pages = Math.ceil(
          state.eventsLatest.items[year].maxItems / ENTRIES_PAGE
        );
        return pages;
      }
    },

    getPagesEvents:
      (state) =>
      (itemsPerPage = ENTRIES_PAGE) => {
        var pages = Math.ceil(state.events.maxItems / itemsPerPage);
        return pages;
      },
    getVideosPage: (state) => {
      return state.videos.viewPage;
    },
    getImagesPage: (state) => {
      return state.images.viewPage;
    },
    getEventCalendar: (state) => {
      return state.eventsCalendar.items;
    },
    // PAGES FIXED
    getPageFixed: (state) => (slug) => {
      return state.pagesFixed[slug];
    },
  },
  actions: {
    async loadBanners() {
      var b = this.banners;
      if (Object.keys(b).length > 0) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
      };
      let t = this;
      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("featureds", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            b[element.id] = element;
          });
          resolve();
          t.loading = false;
        });
      });
    },
    async loadOrganizations({
      page = 1,
      length = 20,
      catId = null,
      textSearch = null,
    } = {}) {
      if (catId == null) {
        catId = 0;
      }
      var o = this.organizations;
      if (Object.keys(o).length > 0 && Object.keys(o).includes(catId)) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "category_org",
            search: catId,
          },
        ],

        order: [
          {
            name: "name",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("entity", data);
        promise.then(function (result) {
          o[catId] = {
            items: [],
          };
          result.data.data.forEach((element) => {
            o[element.category_org].items.push(element);
          });

          resolve();
        });
      });
    },
    async loadSubventions({ maxYear = null } = {}) {
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var s = this.subventions;
      if (Object.keys(s).length > 0) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 10,
              max: maxYear,
            },
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/subvention-helps", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            s[element.year] = {
              items: [],
            };
          });
          result.data.data.forEach((element) => {
            s[element.year].items.push(element);
          });

          resolve();
        });
      });
    },
    async loadAgreements({ maxYear = null } = {}) {
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var ag = this.agreements;
      if (Object.keys(ag).length > 0) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 10,
              max: maxYear,
            },
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/agreement", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            ag[element.year] = {
              items: [],
            };
          });
          result.data.data.forEach((element) => {
            ag[element.year].items.push(element);
          });

          resolve();
        });
      });
    },
    async loadBudgets({ arrayYears = null, maxYear = null } = {}) {
      arrayYears.forEach((element) => {
        this.budgetsAnnual[element] = {
          items: [],
        };
      });
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var b = this.budgetsAnnual;
      if (
        Object.keys(b).length > 0 &&
        Object.keys(b).length.items != undefined
      ) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 4,
              max: maxYear,
            },
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };

      return new Promise((resolve) => {
        var promise = axios.post("reports/annual-budget", data);
        promise.then(function (result) {
          console.log(result);
          result.data.data.forEach((element) => {
            b[element.year].items.push(element);
          });

          resolve();
        });
      });
    },
    async loadMemoriesAnnual({ arrayYears = null, maxYear = null } = {}) {
      arrayYears.forEach((element) => {
        this.memoriesAnnual[element] = {
          items: [],
        };
      });
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var m = this.memoriesAnnual;
      // if (Object.keys(b).length > 0) {
      //     return
      // }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 4,
              max: maxYear,
            },
          },
          {
            name: "type",
            search: 0,
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/annual-report", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            m[element.year].items.push(element);
          });

          resolve();
        });
      });
    },
    async loadMemoriesRSE({ arrayYears = null, maxYear = null } = {}) {
      arrayYears.forEach((element) => {
        this.memoriesRSE[element] = {
          items: [],
        };
      });
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var mrse = this.memoriesRSE;
      // if (Object.keys(b).length > 0) {
      //     return
      // }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 4,
              max: maxYear,
            },
          },
          {
            name: "type",
            search: 1,
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/annual-report", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            mrse[element.year].items.push(element);
          });
          resolve();
        });
      });
    },
    async loadOtherMemories({ maxYear = null } = {}) {
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var om = this.otherMemories;
      if (Object.keys(om).length > 0) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 30,
              max: maxYear,
            },
          },
          {
            name: "type",
            search: 2,
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/annual-report", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            om[element.id] = element;
          });

          resolve();
        });
      });
    },
    async loadAwards({ arrayYears = null, maxYear = null } = {}) {
      arrayYears.forEach((element) => {
        this.awards[element] = {
          items: [],
        };
      });
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var a = this.awards;
      // if (Object.keys(b).length > 0) {
      //     return
      // }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 10,
              max: maxYear,
            },
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/awards", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            a[element.year].items.push(element);
          });
          resolve();
        });
      });
    },

    async loadNews({
      page = 1,
      length = ENTRIES_PAGE,
      maxYear = null,
      filtersResults = {
        textFilter: null,
        dataStart: null,
        dataEnd: null,
      },
    } = {}) {
      // page = checkForNewPage(this.news, page);

      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      if (page == -1) {
        page = 1;
        this.news.items = {};
      } else if (page === null) {
        return;
      }

      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
        // {
        //     "name": "publish_date",
        //     'search': {

        //         "min": new Date(maxYear, 0, 1),
        //         "max": new Date(maxYear, 11, 31)
        //     }

        // }
      ];
      if (filtersResults.dataStart || filtersResults.dataEnd) {
        this.news.items[page] = null;
        filtersSelect.push({
          name: "publish_date",
          search: {
            min: filtersResults.dataStart,
            max: filtersResults.dataEnd,
          },
        });
      }

      if (filtersResults.textFilter) {
        this.news.items[page] = null;
      }
      if (filtersResults.textFilter == null) {
        this.news.items[page] = {};
      }
      //console.log(filtersResults)
      var filters = utils.generateTextFilters(filtersResults.textFilter, [
        "name",
        "header",
      ]);
      let data = {
        page: page,
        length: length,
        filters: filtersSelect.concat(filters),
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };

      let t = this;
      // if (this.news.items[page]) {
      //     if (this.news.items[page]) {
      //         return
      //     }
      // }

      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("entries", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            // if (t.news.items[maxYear] == null) {
            //     t.news.items[maxYear] = {}
            // }
            if (t.news.items[page] == null) {
              t.news.items[page] = {};
            }
            t.news.items[page][element.slug] = element;
            (t.news.viewPage = page),
              (t.news.lastFilters.date = maxYear),
              (t.news.items.maxItems = result.data.recordsFiltered);
          });
          resolve();
          t.loading = false;
        });
      });
    },
    async loadNew({ slug = "default" } = {}) {
      var entry = getEnty(this.news.items, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios.get("entries/" + slug).then(function (result) {
          resolve(result.data);
        });
      });
    },
    async loadResultGoogle({ start = 1,num= 10, textSearch = null } = {}) {
      return new Promise((resolve) => {
        
        axios
          .get(
            "google-search?q=" + textSearch + "&start=" + start + "&num=" + num
          )
          .then(function (result) {
            resolve(result.data);
          });
      });
    },

    async loadNovelties({
      page = 1,
      length = ENTRIES_PAGE,
      maxYear = null,
      filtersResults = {
        textFilter: null,
        dataStart: null,
        dataEnd: null,
      },
    } = {}) {
      // page = checkForNewPage(this.news, page);

      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      if (page == -1) {
        page = 1;
        this.novelties.items = {};
      } else if (page === null) {
        return;
      }

      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
        // {
        //     "name": "publish_date",
        //     'search': {

        //         "min": new Date(maxYear, 0, 1),
        //         "max": new Date(maxYear, 11, 31)
        //     }

        // }
      ];
      if (filtersResults.dataStart || filtersResults.dataEnd) {
        this.novelties.items[page] = null;
        filtersSelect.push({
          name: "publish_date",
          search: {
            min: filtersResults.dataStart,
            max: filtersResults.dataEnd,
          },
        });
      }

      if (filtersResults.textFilter) {
        this.novelties.items[page] = null;
      }
      if (filtersResults.textFilter == null) {
        this.novelties.items[page] = {};
      }
      //console.log(filtersResults)
      var filters = utils.generateTextFilters(filtersResults.textFilter, [
        "name",
        "slug",
      ]);
      let data = {
        page: page,
        length: length,
        filters: filtersSelect.concat(filters),
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };

      let t = this;
      // if (this.news.items[page]) {
      //     if (this.news.items[page]) {
      //         return
      //     }
      // }

      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("novelties", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (t.novelties.items[page] == null) {
              t.novelties.items[page] = {};
            }
            t.novelties.items[page][element.slug] = element;
            (t.novelties.viewPage = page),
              (t.novelties.lastFilters.date = maxYear),
              (t.novelties.items.maxItems = result.data.recordsFiltered);
          });
          resolve();
          t.loading = false;
        });
      });
    },

    async loadNoveti({ slug = "default" } = {}) {
      var entry = getEnty(this.news.items, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios.get("novelties/" + slug).then(function (result) {
          resolve(result.data);
        });
      });
    },

    async loadVideos({ page = 1, length = ENTRIES_PAGE, maxYear = null } = {}) {
      page = checkForNewPage(this.videos, page);
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      if (page == -1) {
        page = 1;
        this.videos.items = {};
      } else if (page === null) {
        return;
      }
      let filtersSelect = [
        {
          name: "url",
          search: "%any_accept%",
        },
        {
          name: "publish_date",
          search: {
            min: new Date(maxYear, 0, 1),
            max: new Date(maxYear, 11, 31),
          },
        },
      ];

      let data = {
        page: page,
        length: length,
        filters: filtersSelect,
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      let t = this;
      if (this.videos.items[maxYear]) {
        if (this.videos.items[maxYear][page]) {
          return;
        }
      }

      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("expositions/images", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (t.videos.items[maxYear] == null) {
              t.videos.items[maxYear] = {};
            }
            if (t.videos.items[maxYear][page] == null) {
              t.videos.items[maxYear][page] = {};
            }
            t.videos.items[maxYear][page][element.slug] = element;
            (t.videos.viewPage = page),
              (t.videos.maxItems = result.data.recordsFiltered);
            (t.videos.lastFilters.date = maxYear),
              (t.videos.items[maxYear].maxItems = result.data.recordsFiltered);
          });
          resolve();
          t.loading = false;
        });
      });
    },

    async loadImages({ page = 1, length = ENTRIES_PAGE, maxYear = null } = {}) {
      page = checkForNewPage(this.images, page);
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      if (page == -1) {
        page = 1;
        this.images.items = {};
      } else if (page === null) {
        return;
      }
      let filtersSelect = [
        {
          name: "url",
          search: "%null_accept%",
        },
        {
          name: "expositions",
          has: true,
          search: false,
        },
        {
          name: "publish_date",
          search: {
            min: new Date(maxYear, 0, 1),
            max: new Date(maxYear, 11, 31),
          },
        },
      ];

      let data = {
        page: page,
        length: length,
        filters: filtersSelect,
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      let t = this;
      if (this.images.items[maxYear]) {
        if (this.images.items[maxYear][page]) {
          return;
        }
      }

      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("expositions/images", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (t.images.items[maxYear] == null) {
              t.images.items[maxYear] = {};
            }
            if (t.images.items[maxYear][page] == null) {
              t.images.items[maxYear][page] = {};
            }
            t.images.items[maxYear][page][element.slug] = element;
            (t.images.viewPage = page),
              (t.images.lastFilters.date = maxYear),
              (t.images.items[maxYear].maxItems = result.data.recordsFiltered);
          });
          resolve();
          t.loading = false;
        });
      });
    },

    async loadMultimediaEntry({ slug = "default" } = {}) {
      var entry = getEnty(this.videos.items, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios.get("expositions/images/" + slug).then(function (result) {
          resolve(result.data);
        });
      });
    },

    async loadEvents({
      page = 1,
      length = ENTRIES_PAGE,
      maxYear = null,
      dateCurrent = null,
      maxDate = null,
    } = {}) {
      page = checkForNewPage(this.events, page);
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      if (dateCurrent == null) {
        dateCurrent = new Date(maxYear, 0, 1);
      } else {
        dateCurrent = new Date(dateCurrent);
      }
      if (maxDate == null) {
        maxDate = new Date(maxYear, 11, 31);
      } else {
        maxDate = new Date(maxDate);
      }
      //console.log(moment(dateCurrent).format('YYYY-MM-DD') )
      if (page == -1) {
        page = 1;
        this.events.items = {};
      } else if (page === null) {
        return;
      }
      let filtersSelect = [
        {
          name: "visible",
          search: 1,
        },
        {
          name: "start_date",
          search: {
            min: moment(dateCurrent).format("YYYY-MM-DD"),
            max: moment(maxDate).format("YYYY-MM-DD"),
          },
        },
      ];

      let data = {
        page: page,
        length: length,
        filters: filtersSelect,
        order: [
          {
            name: "start_date",
            direction: "asc",
          },
        ],
      };
      let t = this;
      if (this.events.items[maxYear]) {
        if (this.events.items[maxYear][page]) {
          return;
        }
      }
      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("events", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (t.events.items[maxYear] == null) {
              t.events.items[maxYear] = {};
            }
            if (t.events.items[maxYear][page] == null) {
              t.events.items[maxYear][page] = {};
            }
            t.events.items[maxYear][page][element.slug] = element;
            // t.events.items[element.slug] = element,
            (t.events.viewPage = page),
              (t.events.maxItems = result.data.recordsFiltered);
          });
          resolve();
          t.loading = false;
        });
      });
    },
    async loadEventsLatests({
      page = 1,
      length = ENTRIES_PAGE,
      maxYear = null,
      dateCurrent = null,
      maxDate = null,
    } = {}) {
      page = checkForNewPage(this.events, page);
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      if (dateCurrent == null) {
        dateCurrent = new Date(maxYear, 0, 1);
      } else {
        dateCurrent = new Date(dateCurrent);
      }
      if (maxDate == null) {
        maxDate = new Date(maxYear, 11, 31);
      } else {
        maxDate = new Date(maxDate);
      }
      //console.log(moment(dateCurrent).format('YYYY-MM-DD') )
      if (page == -1) {
        page = 1;
        this.events.items = {};
      } else if (page === null) {
        return;
      }
      let filtersSelect = [
        {
          name: "start_date",
          search: {
            min: moment(dateCurrent).format("YYYY-MM-DD"),
            max: moment(maxDate).format("YYYY-MM-DD"),
          },
        },
        {
          name: "visible",
          search: 1,
        },
      ];

      let data = {
        page: page,
        length: length,
        filters: filtersSelect,
        order: [
          {
            name: "start_date",
            direction: "desc",
          },
        ],
      };
      let t = this;
      if (this.eventsLatest.items[maxYear]) {
        if (this.eventsLatest.items[maxYear][page]) {
          return;
        }
      }

      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("events", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (t.eventsLatest.items[maxYear] == null) {
              t.eventsLatest.items[maxYear] = {};
            }
            if (t.eventsLatest.items[maxYear][page] == null) {
              t.eventsLatest.items[maxYear][page] = {};
            }
            t.eventsLatest.items[maxYear][page][element.slug] = element;
            // t.eventsLatest.items[element.slug] = element,
            (t.eventsLatest.viewPage = page),
              (t.eventsLatest.lastFilters.date = maxYear),
              (t.eventsLatest.items[maxYear].maxItems =
                result.data.recordsFiltered);
          });
          resolve();
          t.loading = false;
        });
      });
    },

    loadEventsCalendar({
      filtersResults = {
        starDate: null,
        endDate: null,
      },
    } = {}) {
      if (
        JSON.stringify(this.eventsCalendar.lastFilters) ==
        JSON.stringify(filtersResults)
      ) {
        if (
          this.eventsCalendar.items != null &&
          Object.keys(this.eventsCalendar.items).length > 0
        ) {
          return;
        }
      }
      //console.log(filtersResults)
      let filtersSelect = [];
      if (filtersResults.starDate || filtersResults.endDate) {
        filtersSelect.push(
          {
            name: "visible",
            search: 1,
          },
          {
            name: "start_date",
            search: {
              min: filtersResults.starDate,
              max: filtersResults.endDate,
            },
          }
        );
      }
      let data = {
        order: [
          {
            name: "start_date",
            direction: "desc",
          },
        ],

        filters: filtersSelect,
      };

      //Todas las acciones en commit
      let t = this;
      return new Promise((resolve, reject) => {
        var promise = axios.post("events", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            t.eventsCalendar.items[element.slug] = element;
            (t.eventsCalendar.lastFilters.starDate = filtersResults.starDate),
              (t.eventsCalendar.lastFilters.endDate = filtersResults.endDate);
          });
          resolve();
        });
      });
    },
    async loadEvent({ slug = "default" } = {}) {
      var entry = getEnty(this.events.items, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios.get("events/" + slug).then(function (result) {
          resolve(result.data);
        });
      });
    },
    async loadSpecialAvises({ page = 1, length = 10 } = {}) {
      var sa = this.specialAvises;
      if (Object.keys(sa).length > 0) {
        return;
      }
      let data = {
        page: page,
        length: length,

        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        order: [
          {
            name: "created_at",
            direction: "desc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("special-notices", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            sa[element.dataId] = element;
          });

          resolve();
        });
      });
    },
    async loadExpositions({ page = 1, length = 1000 } = {}) {
      var e = this.expositions;
      if (Object.keys(e).length > 0) {
        return;
      }

      let data = {
        page: page,
        length: length,
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        columns: [
          {
            name: "images",
            onlyWith: true,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("expositions/expositions", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            e[element.slug] = element;
          });

          resolve();
        });
      });
    },
    async loadExposition({ slug = "default" } = {}) {
      var entry = getEnty(this.expositions, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios
          .get("expositions/expositions/" + slug + "?c-load[]=images")
          .then(function (result) {
            resolve(result.data);
          });
      });
    },

    async loadRewards({ page = 1 } = {}) {
      var r = this.rewards;
      if (Object.keys(this.rewards).length > 0) {
        return;
      }

      let data = {
        page: page,
        length: 10,

        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("awards", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            r[element.slug] = element;
          });

          resolve();
        });
      });
    },
    async loadReward({ slug = "default" } = {}) {
      var entry = getEnty(this.rewards, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios.get("awards/" + slug).then(function (result) {
          resolve(result.data);
        });
      });
    },
    async loadDocAutodefense() {
      var da = this.documentAutodefense;
      if (Object.keys(da).length > 0) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/self-defense", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            da[element.type] = {
              items: [],
            };
          });
          result.data.data.forEach((element) => {
            da[element.type].items.push(element);
          });

          resolve();
        });
      });
    },

    async loadRoutes() {
      let t = this;
      t.routesTop; //home 1
      t.routesSlider; // featured 1
      t.routesOther; // home 0 featured 0
      if (
        Object.keys(t.routesTop).length > 0 ||
        Object.keys(t.routesSlider).length > 0 ||
        Object.keys(t.routesOther).length > 0
      ) {
        return;
      }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        order: [
          {
            name: "order",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("routes", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            if (element.featured) {
              t.routesTop[element.order] = element;
            }

            if (element.home) {
              t.routesSlider[element.order] = element;
            } else if (element.featured == 0) {
              t.routesOther[element.order] = element;
            }
          });
          resolve(result);
        });
      });
    },
    async loadDocuments() {
      var d = this.documents;
      if (Object.keys(d).length > 0) {
        return;
      }
      let data = {
        length: 2,
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("documents", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            d[element.name] = element;
          });

          resolve();
        });
      });
    },
    async loadCollections({ page = 1, length = 10 } = {}) {
      var c = this.collections;
      if (Object.keys(c).length > 0) {
        return;
      }

      let data = {
        page: page,
        length: length,
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        columns: [
          {
            name: "documents",
            onlyWith: true,
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "desc",
          },
        ],
      };
      let t = this;
      return new Promise((resolve) => {
        t.loading = true;
        var promise = axios.post("collections", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            c[element.slug] = element;
          });
          Object.entries(c).forEach((element) => {
            if (
              c[element[1].slug].documentsId == undefined ||
              c[element[1].slug].documentsId == null
            ) {
              c[element[1].slug].documentsId = {};
            }
            Object.entries(element[1].documents).forEach((doc) => {
              c[element[1].slug].documentsId[doc[1].id] = {
                slugCat: element[1].slug,
              };
              c[element[1].slug].documentsId[doc[1].id] = Object.assign(
                c[element[1].slug].documentsId[doc[1].id],
                doc[1]
              );
            });
            delete c[element[1].slug].documents;
          });

          resolve();
          t.loading = false;
        });
      });
    },
    async loadProjects({ page = 1, length = 10 } = {}) {
      var p = this.projects;
      if (Object.keys(p).length > 0) {
        return;
      }

      let data = {
        page: page,
        length: length,
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
        ],
        columns: [
          {
            name: "banners",
            onlyWith: true,
          },
        ],
        order: [
          {
            name: "order",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("projects", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            p[element.slug] = element;
          });

          resolve();
        });
      });
    },
    async loadProject({ slug = "default" } = {}) {
      var entry = getEnty(this.expositions, slug);
      if (entry) {
        return entry;
      }
      return new Promise((resolve) => {
        axios
          .get("projects/" + slug + "?c-load[]=banners")
          .then(function (result) {
            resolve(result.data);
          });
      });
    },
    async loadPlans({ arrayYears = null, maxYear = null } = {}) {
      arrayYears.forEach((element) => {
        this.plans[element] = {
          items: [],
        };
      });
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var p = this.plans;
      // if (Object.keys(b).length > 0) {
      //     return
      // }
      let data = {
        filterPack: true,
        filters: [
          {
            name: "year",
            search: {
              min: maxYear - 10,
              max: maxYear,
            },
          },
        ],

        order: [
          {
            name: "year",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("reports/action-plans", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            p[element.year].items.push(element);
          });
          resolve();
        });
      });
    },
    async loadRules({ page = 1, length = 100 } = {}) {
      var r = this.rules;
      if (Object.keys(r).length > 0) {
        return;
      }

      let data = {
        page: page,
        length: length,
        filterPack: true,
        filters: [
          {
            name: "visible",
            search: 1,
          },
          {
            name: "mother_id",
            search: "%null_accept%",
          },
        ],
        order: [
          {
            name: "publish_date",
            direction: "asc",
          },
        ],

        columns: [
          {
            name: "childs",
            onlyLoad: true,
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("rules", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            r[element.slug] = element;
            if (r[element.slug].childSlug == null) {
              r[element.slug].childSlug = {};
            }
            r[element.slug].childs.forEach((c) => {
              r[element.slug].childSlug[c.slug] = c;
            });
            delete r[element.slug].childs;
          });

          resolve();
        });
      });
    },
    async loadPresidentAgenda({
      maxYear = null,
      minDay = null,
      maxDay = null,
    } = {}) {
      if (maxYear == null) {
        maxYear = new Date().getFullYear();
      }
      var pa = this.presidentAgenda;
      // if (Object.keys(b).length > 0) {
      //     return
      // }
      let data = {
        // length: 10,
        filterPack: true,
        filters: [
          {
            name: "start_date",
            search: {
              min: moment(minDay),
              max: moment(maxDay),
            },
          },
          {
            name: "visible",
            search: 1,
          },
        ],

        order: [
          {
            name: "start_date",
            direction: "asc",
          },
          {
            name: "start_hour",
            direction: "asc",
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("days", data);
        promise.then(function (result) {
          let i = null;
          for (i = 0; i <= 6; i++) {
            pa[i] = { items: [] };
          }

          result.data.data.forEach((element) => {
            var date = moment(element.start_date);
            pa[date.day()].items.push(element);
            if (element.end_date) {
              var enDate = moment(element.end_date);
              while (enDate.isAfter(date)) {
                date = date.add(1, "days");
                pa[date.day()].items.push(element);
              }
            }
          });
          resolve();
        });
      });
    },
    async loadGoverments({ page = 1, length = 10 } = {}) {
      var gv = this.goverments;
      if (Object.keys(gv).length > 0) {
        return;
      }

      let data = {
        page: page,
        length: length,
        columns: [
          {
            name: "employees",
            onlyLoad: true,
            orderWith: [
              {
                name: "order",
                direction: "asc",
              },
            ],
          },
          {
            name: "employees.employee",
            onlyLoad: true,
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("government-deparments", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            gv[element.slug] = element;
          });
          resolve();
        });
      });
    },
    async loadEmployees({ page = 1 } = {}) {
      var emp = this.employees;
      if (Object.keys(emp).length > 0) {
        return;
      }
      let data = {
        page: page,
        columns: [
          {
            name: "departments.deparment",
            onlyWith: true,
          },
        ],
      };
      return new Promise((resolve) => {
        var promise = axios.post("employees", data);
        promise.then(function (result) {
          result.data.data.forEach((element) => {
            emp[element.slug] = element;

            if (emp[element.slug].departmentSlug == null) {
              emp[element.slug].departmentSlug = {};
            }
            emp[element.slug].departments.forEach((c) => {
              emp[element.slug].departmentSlug[c.deparment.slug] = c;
            });
            delete emp[element.slug].departments;
          });
          resolve();
        });
      });
    },
    async loadPageFixed({ slug = "default" } = {}) {
      console.log(slug);
      var pf = this.pagesFixed;
      if (pf[slug]) {
        return;
      }
      return new Promise((resolve, reject) => {
        axios.get("fixed-pages/" + slug).then(function (result) {
          resolve(result.data);
          if (pf[result.data.slug] == null) {
            pf[result.data.slug] = {};
          }
          pf[result.data.slug] = result.data;
        });
      });
    },
    // async loadNovelties({ page = 1, length = ENTRIES_PAGE, maxYear = null } = {}) {
    //     page = checkForNewPage(this.novelties, page);
    //     if (maxYear == null) {
    //         maxYear = new Date().getFullYear()
    //     }
    //     if (page == -1) {
    //         page = 1;
    //         this.novelties.items = {}
    //     } else if (page === null) {
    //         return;
    //     }
    //     let filtersSelect = [
    //         {
    //             "name": "visible",
    //             "search": 1,
    //         },
    //         {
    //             "name": "publish_date",
    //             'search': {

    //                 "min": new Date(maxYear, 0, 1),
    //                 "max": new Date(maxYear, 11, 31)
    //             }

    //         }
    //     ];

    //     let data = {
    //         page: page,
    //         length: length,
    //         filters: filtersSelect,
    //         order: [
    //             {
    //                 name: 'publish_date',
    //                 direction: 'desc'
    //             }
    //         ],
    //     }
    //     let t = this;
    //     if (this.novelties.items[maxYear]) {
    //         if (this.novelties.items[maxYear][page]) {
    //             return
    //         }
    //     }
    //     return new Promise((resolve) => {
    //         t.loading = true;
    //         var promise = axios.post('novelties', data);
    //         promise.then(function (result) {
    //             result.data.data.forEach(element => {

    //                 if (t.novelties.items[maxYear] == null) {
    //                     t.novelties.items[maxYear] = {}
    //                 }
    //                 if (t.novelties.items[maxYear][page] == null) {
    //                     t.novelties.items[maxYear][page] = {}
    //                 }

    //                 t.novelties.items[maxYear][page][element.slug] = element;
    //                 t.novelties.lastFilters.date = maxYear,
    //                     t.novelties.viewPage = page,
    //                     t.novelties.items[maxYear].maxItems = result.data.recordsFiltered
    //             });
    //             resolve();
    //             t.loading = false;
    //         });
    //     })
    // },
    // async loadNoveti({ slug = 'default' } = {}) {
    //     var entry = getEnty(this.news.items, slug)
    //     if (entry) {
    //         return entry
    //     }
    //     return new Promise((resolve) => {
    //         axios.get("novelties/" + slug)
    //             .then(function (result) {
    //                 resolve(result.data);
    //             });
    //     });
    // },
  },
});