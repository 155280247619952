import { createI18n } from 'vue-i18n'

export const i18n =  createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages: loadLocaleMessages(),
  globalInjection: true,
})


export function loadLocaleMessages() {
  const locales = require.context('./locales/', true, /[A-Za-z0-9-_,\s]+\.json$/i);
 
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\/([A-Za-z0-9-_]+)/i)
    if (matched && matched.length > 2) {
      const locale = matched[1];
      const type = matched[2];
      if(messages[locale] == null){
        messages[locale] = {};
      }
      messages[locale][type] = locales(key);
    }
  });
  return messages
}


export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
      i18n.global.locale = locale
  } else {
      i18n.global.locale.value = locale
  }
  document.querySelector('html').setAttribute('lang', locale)
}