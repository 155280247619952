<template>
  <header class="header" @mouseleave="showMenu = false" :activeMenuTop="menuTop">
    <section class="header-top">
      <div class="container d-flex justify-content-between align-items-start p-top-10-px">
        <router-link :to="{ name: 'home' }" title="CERMI. Innovación social en discapacidad."
          alt="CERMI. Innovación social en discapacidad." class="header-logo">
          <img :src="urlLogo" alt="Ir a Inicio cermi.es" title="CERMI. Innovación social en discapacidad." />
        </router-link>
        <div class="d-flex align-items-center top-header justify-content-end">
          <div class="container-search">
            <input type="text" placeholder="Buscar..." id="searchTool" v-model="textSearch"
              title="Escribe algo para buscar" />
            <button type="button" class="btn-search" @click="sendSearch" aria-label="Click para buscar"
              title="Click para buscar"></button>
          </div>
          <button type="button" class="menu-respons" @click="toggleShowSubMenuResponsive"
            aria-label="Abrir menú principal" title="Abrir menú principal">
            Menú
          </button>
          <div class="rss">
            <span title="Síguenos en" alt="Síguenos en">Síguenos en</span>
            <a href="https://www.facebook.com/Cermi-Estatal-338031889614106/" title="Link a Facebook" alt="Link a "
              target="_blank">
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>

            <a href="https://twitter.com/Cermi_Estatal" title="Link a X" alt="Link a " target="_blank">
              <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
            </a>
            <a href="https://www.instagram.com/cermi_estatal/" title="Link a Instagram" alt="Link a " target="_blank">
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </a>

            <a href="https://es.linkedin.com/in/cermi-estatal-8ab39655" title="Link a Linkedin" alt="Link a Linkedin"
              target="_blank">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="https://www.youtube.com/user/CERMIESTATAL/videos" title="Link a Youtube" alt="Link a "
              target="_blank">
              <i class="fa fa-youtube" aria-hidden="true"></i>
            </a>
            <a href="https://t.me/cermiestatal" title="Link a Telegram" alt="Link a Telegram" target="_blank">
              <i class="fa fa-telegram" aria-hidden="true"></i>
            </a>
            <a href="https://www.tiktok.com/@cermiestatal" title="Link a Tiktok" alt="Link a Tiktok" target="_blank">
              <i class="fa-brands fa-tiktok" aria-hidden="true"></i>
            </a>
            <a href="https://bsky.app/profile/cermiestatal.bsky.social" title="Link a bluesky" alt="Link a bluesky"
              target="_blank">
              <img src="/img/icons/bluesky-white.svg" alt="Link a bluesky">
              <img src="/img/icons/bluesky-blue.svg" alt="Link a bluesky" class="img-hover">

            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="menu-header">
      <ul class="nav-menu container">
        <li>
          <router-link :to="{ name: 'home' }" title="Ir a página de inicio" alt="Ir a página de inicio">Inicio
          </router-link>
        </li>
        <li>
          <router-link :class="[showMenu ? 'router-link-active link-menu' : 'link-menu']" :to="{ name: 'home' }"
            title="Abrir submenú de Cermi" alt="Abrir submenú de Cermi" @click="toggleShowSubMenu"
            :aria-expanded="showMenu">CERMI
          </router-link>
        </li>
        <li>
          <router-link :to="{
            name: 'actualidad',
          }" title="Ir a página de Actualidad" alt="Ir a página de Actualidad">Actualidad
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'defensa-legal' }" title="Ir a página de Defensa Legal"
            alt="Ir a página de Defensa Legal">Defensa Legal
          </router-link>
        </li>

        <li v-for="(collection, key) in Object.values(store.collections).slice(
          0,
          1
        )" :key="key" :value="key">
          <router-link :to="{
            name: 'colecciones',
            params: {
              id: collection.slug,
            },
          }" title="Ir a página de Colecciones" alt="Ir a página de Colecciones">Colecciones
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'transparencia' }" title="Ir a página de Transparencia"
            alt="Ir a página de Transparencia">Transparencia
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'contacto' }" title="Ir a página de Contacto" alt="Ir a página de Contacto">
            Contacto
          </router-link>
        </li>
      </ul>
    </section>
    <section class="menu-header responsive">
      <ul class="nav-menu container">
        <li class="container-responsiv" :showMenuResponsive="showMenuResponsive">
          <ul>
            <li>
              <router-link :to="{ name: 'home' }" title="Ir a página de inicio" alt="Ir a página de inicio">Inicio
              </router-link>
            </li>
            <li>
              <span :class="[
                showMenu ? 'router-link-active link-menu' : 'link-menu',
              ]" to="{ name: 'cermi' }" title="Abrir submenú de Cermi" alt="Abrir submenú de Cermi"
                @click="toggleShowSubMenu">Cermi
              </span>
            </li>

            <li>
              <router-link :to="{
                name: 'actualidad',
              }" title="Ir a página de Actualidad" alt="Ir a página de Actualidad">Actualidad
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'defensa-legal' }" title="Ir a página de Defensa Legal"
                alt="Ir a página de Defensa Legal">Defensa Legal
              </router-link>
            </li>

            <li>
              <router-link v-for="(collection, key) in Object.values(
                store.collections
              ).slice(0, 1)" :key="key" :value="key" :to="{
                  name: 'colecciones',
                  params: {
                    id: collection.slug,
                  },
                }" title="Ir a página de Colecciones" alt="Ir a página de Colecciones">Colecciones
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'transparencia' }" title="Ir a página de Transparencia"
                alt="Ir a página de Transparencia">Transparencia
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'contacto' }" title="Ir a página de Contacto" alt="Ir a página de Contacto">
                Contacto
              </router-link>
            </li>
            <li>
              <div class="container-search -responsive">
                <input type="text" placeholder="Buscar..." id="searchTool" v-model="textSearch"
                  title="Escribe algo para buscar" />
                <button type="button" class="btn-search" @click="sendSearch" aria-label="Click para buscar"
                  title="Click para buscar"></button>
              </div>
            </li>
            <li class="rss responsive">
              <span title="Síguenos en" alt="Síguenos en">Síguenos en</span>
              <a href="https://www.facebook.com/Cermi-Estatal-338031889614106/" title="Link a Facebook" alt="Link a "
                target="_blank">
                <i class="fa fa-facebook" aria-hidden="true"></i>
              </a>
              <a href="https://twitter.com/Cermi_Estatal" title="Link a X" alt="Link a " target="_blank">
                <i class="fa-brands fa-x-twitter" aria-hidden="true"></i>
              </a>
              <a href="https://www.instagram.com/cermi_estatal/" title="Link a Instagram" alt="Link a " target="_blank">
                <i class="fa fa-instagram" aria-hidden="true"></i>
              </a>

              <a href="https://es.linkedin.com/in/cermi-estatal-8ab39655" title="Link a Linkedin" alt="Link a Linkedin"
                target="_blank">
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </a>
              <a href="https://www.youtube.com/user/CERMIESTATAL/videos" title="Link a Youtube" alt="Link a "
                target="_blank">
                <i class="fa fa-youtube" aria-hidden="true"></i>
              </a>
              <a href="https://t.me/cermiestatal" title="Link a Telegram" alt="Link a Telegram" target="_blank">
                <i class="fa fa-telegram" aria-hidden="true"></i>
              </a>
              <a href="https://www.tiktok.com/@cermiestatal" title="Link a Tiktok" alt="Link a Tiktok" target="_blank">
                <i class="fa-brands fa-tiktok" aria-hidden="true"></i>
              </a>
              <a href="https://bsky.app/profile/cermiestatal.bsky.social" title="Link a bluesky" alt="Link a bluesky"
                target="_blank">
                <img src="/img/icons/bluesky-white.svg" alt="Link a bluesky">
                <img src="/img/icons/bluesky-blue.svg" alt="Link a bluesky" class="img-hover">

              </a>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    <section class="submenu-tem" :showMenu="showMenu" :aria-hidden="showMenu">
      <button @click="toggleShowSubMenu" class="cerrar-cermi-resp">
        <img src="/icons/arrow_left_white.svg" alt="Cerrar" />
        <span>Volver</span>
      </button>
      <div class="container grid-4">
        <ul class="grid justify-content-between">
          <p class="title-dest-underline-white">CERMI</p>
          <li>
            <router-link :to="{ name: 'que-es-cermi' }" title="Ir a página de Qué es el CERMI"
              alt="Ir a página de Qué es el CERMI">Qué es el CERMI
            </router-link>
          </li>

          <li>
            <router-link :to="{
              name: 'organizaciones',
              params: {
                slug: 'socios-fundadores',
              },
            }" title="Ir a página de Organizaciones" alt="Ir a página de Organizaciones">Organizaciones
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'condecoraciones-y-premios' }" title="Ir a página de Condecoraciones y premios"
              alt="Ir a página de Condecoraciones y premios">Condecoraciones y premios
            </router-link>
          </li>

          <li>
            <router-link :to="{ name: 'memorias' }" title="Ir a página de Memorias anuales "
              alt="Ir a página de Memorias anuales ">Memorias anuales
            </router-link>
          </li>

          <li class="m-bottom-0">
            <router-link :to="{ name: 'Información-economica' }" title="Ir a página de Información económica"
              alt="Ir a página de Información económica" class="title-dest-underline-white m-top-20-px w-100 d-flex">
              Información económica
            </router-link>
          </li>

          <li>
            <router-link to="/informacion-economica#subvenciones-ayudas"
              title="Ir a página de  Subvenciones y ayudas públicas"
              alt="Ir a página de  Subvenciones y ayudas públicas">
              Subvenciones y ayudas públicas
            </router-link>
          </li>
          <li>
            <router-link to="/informacion-economica#colaboraciones-economicas"
              title="Ir a página de Colaboraciones económicas privadas"
              alt="Ir a página de Colaboraciones económicas privadas">Colaboraciones económicas privadas
            </router-link>
          </li>
          <li>
            <router-link to="/informacion-economica#cuentas-presupuestos"
              title="Ir a página de Cuentas y presupuestos anuales" alt="Ir a página de Cuentas y presupuestos anuales">
              Cuentas y presupuestos anuales
            </router-link>
          </li>
        </ul>
        <ul class="grid justify-content-between">
          <li class="m-bottom-0">
            <router-link :to="{ name: 'asi-nos-organizamos' }" title="Ir a página de Gobernanza CERMI"
              alt="Ir a página de Gobernanza CERMI" class="title-dest-underline-white w-100 d-flex">Gobernanza CERMI
            </router-link>
          </li>

          <li>
            <router-link :to="{
              name: 'asi-nos-organizamos',
              params: { slug: 'organigrama' },
            }" title="Ir a página de  Organigrama" alt="Ir a página de  Organigrama">
              Organigrama
            </router-link>
          </li>
          <li>
            <router-link :to="{
              name: 'asi-nos-organizamos',
              params: { slug: 'organos-gobierno' },
            }" title="Ir a página de   Órganos de gobierno" alt="Ir a página de   Órganos de gobierno">
              Órganos de gobierno
            </router-link>
          </li>
          <li>
            <router-link :to="{
              name: 'asi-nos-organizamos',
              params: { slug: 'equipo-humano' },
            }" title="Ir a página de  Equipo Humano" alt="Ir a página de  Equipo Humano">
              Equipo Humano
            </router-link>
          </li>

          <li class="m-bottom-0">
            <router-link :to="{ name: 'iniciativas-cermi' }" title="Ir a página de Iniciativas CERMI"
              alt="Ir a página de Iniciativas CERMI" class="title-dest-underline-white m-top-20-px w-100 d-flex">
              Iniciativas CERMI
            </router-link>
          </li>
          <li>
            <router-link :to="{
              name: 'iniciativas-cermi',
              params: { slug: 'ambitos-actuacion' },
            }" title="Ir a página de Ámbitos de actuación" alt="Ir a página de   Ámbitos de actuación">
              Ámbitos de actuación
            </router-link>
          </li>
          <li>
            <router-link to="/iniciativas-cermi#acciones-internacionales"
              title="Ir a página de Acciones internacionales" alt="Ir a página de Acciones internacionales">
              Acciones internacionales
            </router-link>
          </li>
          <li>
            <router-link to="/iniciativas-cermi#proyectos" title="Ir a página de Proyectos e iniciativas"
              alt="Ir a página de Proyectos e iniciativas">
              Proyectos e iniciativas
            </router-link>
          </li>
        </ul>
        <ul class="grid">
          <li class="m-bottom-0">
            <router-link to="/normas-reguladoras/normas-reguladoras-del-cermi"
              title="Ir a página de  Normas reguladoras del CERMI" alt="Ir a página de  Normas reguladoras del CERMI"
              class="title-dest-underline-white m-top-0-px w-100 d-flex">Normas, Códigos y Planes
            </router-link>
          </li>
          <li>
            <router-link to="/normas-reguladoras/normas-reguladoras-del-cermi"
              title="Ir a página de  Normas reguladoras del CERMI" alt="Ir a página de  Normas reguladoras del CERMI">
              Normas reguladoras del CERMI
            </router-link>
          </li>
          <li>
            <router-link to="/normas-reguladoras/codigo-del-buen-gobierno-del-cermi"
              title="Ir a página de  Código del buen gobierno" alt="Ir a página de  Código del buen gobierno">Código del
              buen gobierno
            </router-link>
          </li>

          <li>
            <router-link to="/normas-reguladoras/codigo-etico-del-cermi" title="Ir a página de  Código ético"
              alt="Ir a página de  Código ético">
              Código ético
            </router-link>
          </li>
          <li>
            <router-link to="/normas-reguladoras/plan-de-igualdad" title="Ir a página de Plan de igualdad"
              alt="Ir a página de Plan de igualdad">Plan de igualdad
            </router-link>
          </li>
          <li>
            <router-link to="/normas-reguladoras/plan-personal-lgtbi" title="Ir a página de Plan Personal LGTBI+"
              alt="Ir a página de Plan Personal LGTBI+">Plan Personal LGTBI+
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'normas-reguladoras', params: { slug: 'calidad' } }"
              title="Ir a página de Calidad" alt="Ir a página de Calidad">Calidad
            </router-link>
          </li>
          <li>
            <router-link
              to="/normas-reguladoras/observancia-normativa-en-el-seno-del-cermi-estatal-denuncia-de-ilicitos"
              title="Ir a página de Observancia normativa en el seno del CERMI"
              alt="Ir a página de Observancia normativa en el seno del CERMI">Observancia normativa en el seno del CERMI
            </router-link>
          </li>
          <li>
            <router-link to="/normas-reguladoras/sistema-de-prevencion-de-delitos-penales"
              title="Ir a página de Sistema de prevención de delitos penales"
              alt="Ir a página de Sistema de prevención de delitos penales">Sistema de prevención de delitos penales
            </router-link>
          </li>
          <li class="m-bottom-0">
            <router-link :to="{ name: 'convenios' }" title="Ir a página de Convenios y acuerdos institucionales"
              alt="Ir a página de Convenios y acuerdos institucionales"
              class="title-dest-underline-white m-top-20-px w-100 d-flex">
              Convenios y acuerdos institucionales
            </router-link>
          </li>
        </ul>
        <ul class="grid">
          <li>
            <router-link class="link-dest" to="/cermis-autonomicos" title="Ir a página de  CERMIs Autonómicos"
              alt="Ir a página de  CERMIs Autonómicos">
              CERMIs Autonómicos
            </router-link>
          </li>
          <li>
            <router-link class="link-dest" :to="{ name: 'planes-actuacion-anuales' }"
              title="Ir a página de Planes de actuación anuales" alt="Ir a página de Planes de actuación anuales">Planes
              de actuación anuales
            </router-link>
          </li>
          <li>
            <router-link class="link-dest" :to="{ name: 'como-asociarse-al-cermi' }"
              title="Ir a página de Cómo asociarse al CERMI" alt="Ir a página de Cómo asociarse al CERMI">Cómo asociarse
              al CERMI
            </router-link>
          </li>
          <li>
            <router-link class="link-dest" :to="{ name: 'voluntariado-social-cermi' }"
              title="Ir a página de Voluntariado Social CERMI" alt="Ir a página de Voluntariado Social CERMI">
              Voluntariado Social CERMI
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </header>
</template>
<script>
import { contentStore } from "@/stores/contents";
export default {
  name: "HeaderLayout",
  data() {
    return {
      newLogo: false,
      urlLogo: "/img/logo.jpg",
      showMenu: false,
      showMenuResponsive: false,
      textSearch: null,
      menuTop: false,
    };
  },
  setup() {
    const store = contentStore();
    store.loadCollections();
    return {
      store,
    };
  },
  mounted() {
    this.topHeader();
    const fechaActual = new Date().getTime();
    const fechaInit = new Date(2024, 5, 15).getTime();
    if (fechaActual <= fechaInit) {
      this.urlLogo = "/img/logo.jpg";
    }
  },
  methods: {
    toggleShowSubMenu: function () {
      this.showMenu = !this.showMenu;
    },
    toggleShowSubMenuResponsive: function () {
      this.showMenuResponsive = !this.showMenuResponsive;
      // console.log(this.showMenuResponsive);
    },
    topHeader: function () {
      window.onscroll = () => {
        if (window.scrollY > 0) {
          this.menuTop = true;
        } else {
          this.menuTop = false;
        }
      };
    },
    sendSearch: function () {
      this.$router.push({ name: "results", query: { key: this.textSearch } });
    },
  },
  watch: {
    $route() {
      (this.showMenu = false), (this.showMenuResponsive = false);
      if (!this.$route.query.key) {
        this.textSearch = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/general.scss";
@import "@/styles/form.scss";
@import "@/styles/icons.scss";

.cerrar-cermi-resp {
  display: none;
}

.container-responsiv {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 135px);
  padding-right: 40px;

  .content.responsive {
    display: none;
  }
}

.rss.responsive,
.menu-header.responsive {
  display: none;
}

.header {
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  height: 0;

  .header-top {
    width: 100%;
    background: white;
    position: relative;
    z-index: 15;

    .header-logo {
      width: 30%;

      img {
        max-width: 170px;
        width: 100%;
        transition: all 0.8s ease-in-out;
      }
    }

    .top-header {
      width: 70%;
      transform: translateY(10px);
    }
  }

  .container-search {
    width: 40%;
    border: 1px solid $blue;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 50px;
    margin-right: 15px;
    border-radius: 33px;

    input {
      background: transparent;
      font-size: 14px;
      color: $blue;
      border: 0;
      padding: 0 10px;
      width: 100%;
      max-width: 400px;

      &:focus {
        outline: none;
        background: transparent;
      }

      &::placeholder {
        color: $blue;
      }
    }

    .btn-search {
      background-image: url(/icons/search.svg);
      width: 30px;
      height: 30px;
      background-size: 30px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
      border: 0;
    }
  }

  .menu-header {
    width: 100%;
    position: relative;
    z-index: 16;
    background: white;
    padding-bottom: 5px;
    padding-left: 230px;

    .nav-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background: white;
      position: relative;
      z-index: 1;

      li {
        min-width: 180px;
        width: max-content;
        list-style: none;
        padding: 0 30px;

        a,
        .link-menu {
          text-decoration: none;
          font-family: "Frank Ruhl Libre", serif;
          font-size: 18px;
          color: $blue;
          position: relative;
          cursor: pointer;

          &:before {
            content: "";
            width: 0;
            height: 1px;
            border-radius: 10px;
            background: #0069b4;
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            bottom: -5px;
            transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
          }

          &:hover {
            &:before {
              width: 100%;
              transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
            }
          }

          &.router-link-active {
            font-weight: 700;

            &:before {
              content: "";
              width: 100%;
              height: 1px;
              border-radius: 10px;
              background: #0069b4;
              position: absolute;
              right: 0;
              left: 0;
              margin: 0 auto;
              bottom: -5px;
              transition: all 0.8s cubic-bezier(0, 1, 0.5, 1);
            }
          }
        }
      }
    }
  }

  .menu-respons {
    font-family: "Frank Ruhl Libre", serif;
    font-weight: 700;
    border: 0;
    font-size: 18px;
    background: transparent;
    position: relative;
    cursor: pointer;
    padding-left: 45px;
    display: block;

    &[showmenuresponsive="true"] {
      position: relative;

      &:after {
        content: "";
        width: 32px;
        height: 4px;
        border-radius: 5px;
        background: $blue;
        position: absolute;
        bottom: -10px;
        right: 11px;
        /* left: 0; */
        margin: 0 auto;
      }
    }
  }

  .submenu-tem {
    padding: 20px;
    background: $blue;
    width: 100%;
    position: relative;
    z-index: 0;
    transform: translateY(-102%);
    transition: all 0.8s ease-in-out;

    &[showmenu="true"] {
      /* z-index: 16; */
      transform: translateY(0);
      transition: all 0.8s ease-in-out;
    }

    .grid-4 {
      justify-content: space-between;

      .grid {
        width: 21%;
        display: flex;
        flex-direction: column;
      }
    }

    .title-dest-underline-white {
      font-size: 18px;
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 10px;

        a {
          font-family: "Frank Ruhl Libre", serif;
          color: white;
          font-size: 16px;
          text-decoration: none;

          &.link-dest {
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 10px;
            display: inline-block;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &[activemenutop="true"] {
    .menu-header {
      -webkit-box-shadow: 0px 8px 10px -12px rgb(0 0 0 / 75%);
      -moz-box-shadow: 0px 8px 10px -12px rgb(0 0 0 / 75%);
      box-shadow: 0px 8px 10px -12px rgb(0 0 0 / 75%);
    }

    .header-logo img {
      max-width: 180px;
      transition: all 0.8s ease-in-out;
    }
  }

  .header .container-search.-responsive {
    display: none;
  }
}

.header .menu-respons {
  display: none;
}

@media screen and (max-width: 895px) {
  .header .container-search.-responsive {
    display: flex;
    width: 100%;
    margin: 30px 0;
  }

  .header .header-top .container-search {
    display: none;
  }

  /******************************************************** */
  .menu-header,
  .rss {
    display: none;
  }

  .menu-header.responsive {
    display: block;
    padding-bottom: 0px;
  }

  .rss.responsive {
    display: flex;
  }

  .header .menu-header .nav-menu li {
    min-width: 100%;
  }

  .container-responsiv {
    width: 100%;
    position: absolute;
    flex-direction: column;
    top: 10px;
    display: flex;
    background: #dcebf6;
    padding: 30px !important;
    position: absolute;
    transition: all 0.5s ease-in-out;
    transform: translateX(-100%);
    right: 0;
    left: 0;
    z-index: 2;

    li {
      text-align: center;
      margin-bottom: 15px;
    }

    &[showmenuresponsive="true"] {
      transform: translateX(0%);
      padding-left: 0 !important;
    }
  }

  .header .submenu-tem[showmenu="true"] {
    z-index: 16;
  }

  .header .submenu-tem .grid-4 .grid {
    width: 45%;
    display: flex;
    flex-direction: column;
  }

  .header .menu-respons {
    font-family: "Frank Ruhl Libre", serif;
    font-weight: 700;
    border: 0;
    font-size: 18px;
    background: transparent;
    position: relative;
    cursor: pointer;
    padding-right: 45px;
    display: none;
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0;
  }

  .header .menu-respons:before {
    content: "";
    width: 40px;
    height: 40px;
    display: inline-block;
    background-image: url(/img/icons/icon_menu_black.svg);
    background-size: 40px;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    right: 0;
    bottom: -5px;
  }

  .menu-respons {
    margin-top: 15px;
  }

  .header .menu-header {
    padding-left: 0;
  }

  .header .menu-header .nav-menu li.container-responsiv {
    margin-bottom: 4vh;
    height: 100vh;
  }

  .rss span {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .rss.responsive {
    justify-content: center;
    align-items: center;
  }

  .header .menu-header .nav-menu li a,
  .header .menu-header .nav-menu li .link-menu {
    font-size: 20px;
  }

  .header .submenu-tem[showmenu="true"] {
    z-index: 88;
    transform: translateX(0);
  }

  .header .submenu-tem {
    z-index: 88;
    transform: translateX(-102%);
  }

  .header .submenu-tem .grid-4 .grid {
    align-items: flex-start;
    margin-bottom: 35px;
  }

  .cerrar-cermi-resp {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;

    img {
      transform: rotate(180deg);
      max-width: 25px;
      width: 100%;
    }

    span {
      color: white;
      font-size: 18px;
      font-family: "Frank Ruhl Libre";
      width: calc(100% - 30px);
      font-weight: bold;
      margin-left: 4px;
    }
  }

  .header .submenu-tem[showmenu="true"] {
    z-index: 16;
    height: 100vh;
    overflow: scroll;
  }

  .header .submenu-tem .grid-4 .grid:first-of-type {
    margin-top: 0;
  }

  .header .submenu-tem .grid-4 .grid:last-of-type {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .header .submenu-tem .grid-4 .grid {
    margin-bottom: 0px;
    margin-right: 0;
    height: 100%;
    min-height: 260px;
    margin-top: 25px;
  }

  .cerrar-cermi-resp {
    margin-bottom: 15px;
  }

  .header .header-top .header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header .header-top .header-logo img {
    max-width: 120px;
  }

  .header-top .container {
    flex-direction: initial;
    flex-wrap: wrap;
    padding: 0;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 750px) {
  .header .header-top .header-logo {
    width: 30% !important;
  }

  .header .submenu-tem .grid-4 .grid {
    width: 100%;
    align-items: center;
  }

  .cerrar-cermi-resp {
    margin-bottom: 0;
  }

  /* .header .header-top .container-search {
      max-height: 40px;
    } */

  .header .submenu-tem .title-dest-underline-white {
    text-align: center;
  }

  /* 
   

   */
}
</style>
